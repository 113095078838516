@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.resturant_image {
	background-color: #eee;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 20vw;
	max-height: 300px;
}

.menu_header {
	margin-top: 2em;
	text-align: center;

	.type {
		margin-bottom: 0;
		color: map-get($colors, "gray");
	}

	.description {
		font-size: 14px;
		line-height: 20px;
        color: #454545;
        margin: 0 0 0.4rem 0;
	}

	.openings {
		font-size: 14px;
		line-height: 20px;
        color: #7d7d7d;
        margin: 0 0 0.4rem 0;
	}
}

.search_field{
	margin: 1rem 0 0 0;
}

.category_selector{
    margin: 0 0 0 0;
}

.search_content{
	margin: 1rem 0 0 0;

	&>p:first-child{
		text-align: center;
	}
}

.course {
    margin: 2rem 0 0 0;
    
    &:first-of-type{
        margin: 0;
    }

	h4 {
		font-size: 24px;
		line-height: 36px;
		font-weight: bold;
		text-align: center;
	}

	.sub_category{
		font-style: italic;
		font-size: bold;
		text-align: center;
	}

	.dish {
        margin: 0em 0 1rem 0;
	    padding: 0 0 1rem 0;
        border-bottom: 1px solid rgba(213, 236, 244, 0.837701);

		.dish_title {
			font-size: 20px;
			line-height: 30px;
            color: #454545;
            margin-bottom: 0.4rem;
		}

		.dish_description {
			font-size: 14px;
			line-height: 21px;
            color: #454545;
            margin-bottom: 0.4rem;
		}

		.dish_ingredients {
			font-size: 14px;
			line-height: 21px;
            color: #7d7d7d;
            margin-bottom: 0.4rem;
		}
	}
}
