@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.join_intro{
    background: #00B2EA !important;
}

.baloon{
    position: absolute;
    width:100%;
    height:100%;

    overflow: hidden;
    @include media-breakpoint-up(md){
        overflow: visible;
    }

    .positioner{
        position: absolute;
        /*width: 100%;*/
        height: 100%;

        margin-top:5px;
        right: -40vh;
        transition: right linear 0.5s;

        @include media-breakpoint-up(md){
            margin-top: 3vh;
            right: 15px;
        }

        img{
            height:100%;   
        }
    }
}