@import '~bootstrap/scss/bootstrap';
@import "variables";

.credits-header {
	text-align: center;
	margin-bottom: 2em;

	h1 {
		font-weight: bold;
	}
}

.credits-section {
	margin: 0em 0 3em 0;
	padding-top: 1em;
	border-top: 2px solid #eee;

	&:first-of-type {
		border: none;
		margin-top: 0;
	}

	.credits-section-container {
		margin-top: 2em;
		

		@include media-breakpoint-up(md){
			display: flex;
			justify-content: center;
		}
		
	}
}

.person {
	background: $card-bg;
	border-radius: $card-border-radius;
	box-shadow: $card-box-shadow;
	text-align: center;
	margin: auto;
	width: 200px;

	@include media-breakpoint-up(md){
		margin: 0 2vw 0 2vw;
	}

	.person-img {
		width: 200px;
		height: 200px;
		border-radius: $card-border-radius $card-border-radius 0 0;
		overflow: hidden;
	}

	.person-info {
		padding: 1.2em 0 0 0;

		.person-name {
			font-weight: bold;
			font-size: 1.3em;
			margin-bottom: 0.2em;
		}

		.person-role {
			font-size: 0.8em;
			color: rgb(179, 179, 179);
			margin: 0;
		}
	}

	.person-social {
		margin: 0.6em 0 0.6em 0;
		padding-top: 0.6em;
		border-top: 1px solid #eee;
		font-size: 1.3em;
		a {
			color: $main_color;
            margin: 0 0.2em 0 0.2em;
            text-decoration: none !important;
		}
	}
}
