@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import "variables.scss";
@import "./fonts/icofont/icofont.min.css";
@import "./fonts/fontello-6ae37132/css/fontello.css";
@import "./fonts/beauty/stylesheet.css";
@import "~bootstrap/scss/bootstrap";
@import "credits.scss";

body {
	margin: 0;
	font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body_bg_color;
	color: $main_color;
}

.old-browser {
	margin-bottom: 0;
	text-align: center;
	position: fixed;
	width: 100%;
	z-index: 10000;
}

#App {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.btn.white-button {
	background: #fff;
	color: #006f92;

	&:hover {
		color: #006f92;
		text-decoration: underline;
	}
}

#intro {
	background: url("/intro-bg-compressor.png");
	min-height: 100vh;
	position: relative;
	/*overflow: hidden;*/
	background-size: cover;

	.bg-gradient {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.5;
		top: 0;
		left: 0;
		//background: linear-gradient(90deg, #000000 40.83%, rgba(0, 0, 0, 0) 96.65%);
		background: linear-gradient(
			90deg,
			#009bcc 40.83%,
			rgba(0, 0, 0, 0) 96.65%
		);
	}

	#logo {
		position: absolute;
		top: 9vh;
		z-index: 100;
	}

	#intro-content {
		position: relative;
		top: 25vh;
		z-index: 1;

		p {
			font-size: 7vmin;
			line-height: 8vmin;
			/* or 120% */
			color: #ffffff;
			margin-bottom: 0;
		}

		button,
		.btn {
			font-weight: bold;
			font-size: 3vmin;
			color: #fff;
			background: #009bcc;
			border-radius: 5px;
			border: none;
			padding: 9px 22px;
			margin-top: 3.5vmin;
			margin-left: 1vw;

			&:first-child {
				margin-left: 0;
			}

			&.white-button {
				background: #fff;
				color: #006f92;
			}

			&.dark-button {
				background: #006f92;
				color: #fff;
			}
		}
	}

	#mouse-scroll {
		position: absolute;
		color: #fff;
		font-size: 6.5vh;
		bottom: 0;
		width: 100%;
		text-align: center;
		z-index: 0;

		@keyframes animate-scroll {
			0% {
				transform: translateY(0);
			}
			50% {
				transform: translateY(-6vh);
			}
			100% {
				transform: translateY(0);
			}
		}

		animation-name: animate-scroll;
		animation-duration: 3s;
		animation-timing-function: ease;
		animation-iteration-count: infinite;
	}
}

.clusu-btn {
	font-weight: bold;
	font-size: 16px;
	color: #fff;
	background: #009bcc;
	border-radius: 5px;
	border: none;
	padding: 9px 22px;
	margin-top: 3.5vmin;
	margin-left: 1vw;
	cursor: pointer;

	&:first-child {
		margin-left: 0;
	}

	&.white-button {
		background: #fff;
		color: #006f92;
	}

	&.dark-button {
		background: #006f92;
		color: #fff;
	}
}

.bottom-line {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		display: block;
		margin: auto;
		left: 0;
		right: 0;
		width: 80%;
		height: 3px;
		background: #ffcc14;
	}
}

#main-content {
	flex-grow: 1;
}

.main-navbar {
	background: #454545;
}

#footer {
	padding: 39px 0 13px 0;
	color: #fff;

	background: #454545;
	font-size: 12px;
	bottom: 0;
	width: 100%;
	text-align: center;

	@include media-breakpoint-up(md) {
		text-align: left;
	}

	a {
		color: #fff !important;
		text-decoration: none !important;

		&:hover {
			color: rgb(230, 230, 230) !important;
		}
	}

	.footer-link-list {
		text-align: center;
		font-size: 1.2em;
		font-weight: bold;
		margin: 25px 0 0px 0;

		@include media-breakpoint-up(md) {
			margin: 0;
		}
	}

	.fb-like-button {
		margin-bottom: 10px;
	}

	.social {
		font-size: 1.6em;
		margin-bottom: 10px;

		a {
			padding-right: 0.5em;

			&:last-of-type {
				padding-right: 0;
			}
		}
	}

	.copyright {
		font-family: 20px;
		line-height: 50px;
	}
}

.dark-section {
	background: #006f92;
	color: #fff;
}

.yellow-section {
	background: #ffcc14;
}

.clusu-numbers {
	text-align: center;

	.value {
		color: #fff;
		font-weight: bold;
		font-size: 60px;
		line-height: 40px;
	}

	.label {
		font-weight: bold;
		font-size: 25px;
		line-height: 35px;

		color: #005975;
	}
}

.info-section {
	padding: 30px 0 25px 0;

	.info-header {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 40px;
		text-align: center;
		padding: 20px 0 15px 0;
	}

	.info-content {
		font-family: Poppins;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 25px;
		text-align: left;
	}

	.coupon-example {
		text-align: center;
		position: relative;

		img {
			position: relative;
			max-width: 70%;
			margin: auto;
			margin-bottom: 2em;

			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
	}

	.howto-side {
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include media-breakpoint-up(md) {
			min-height: 300px;
		}
	}

	&.section-aria {
		padding: 36px 0 56px 0;

		.info-header {
			margin-bottom: 25px;
		}
	}
}

.category-selector {
	padding: 15px 15px 15px 15px;
	margin: 15px 0 10px 0;
	//margin-bottom: 2.5vh;

	.category-selector-button {
		background: #fff;
		border: 1px solid #009bcc;
		box-sizing: border-box;
		border-radius: $button-border-radius;
		color: #009bcc;
		//font-weight: bold;
		//font-size: 20px;
		padding: 8px 25px 8px 25px;
		margin: 0 2vw 2.5vh 0;

		transition: 0.4s linear;

		&:last-of-type {
			margin-right: 0;
		}

		&.selected {
			color: #fff;
			//font-weight: bold;
			background: #009bcc;
		}
	}

	fieldset:disabled {
		.category-selector-button {
			background-color: #fff;
			border: 1px solid #cccccc;
			color: #cccccc;
			font-weight: normal;
		}
	}
}

#shop-selector {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	column-gap: 4vw;
	row-gap: 3vw;
	align-content: start;
	margin-bottom: 15px;

	&.selected {
		.shop {
			opacity: 0.4;

			.shop-top-border {
				//background: rgb(202, 202, 202);
				filter: grayscale(100%);
			}
		}
	}

	&.checkout {
		.shop {
			cursor: auto;

			&:hover {
				transform: scale(1);
			}
		}
	}

	.shop {
		background: $card-bg;
		border-radius: $card-border-radius;
		box-shadow: $card-box-shadow;
		transition: linear 0.3s;
		transition-property: box-shadow, opacity, transform;
		//padding: 0.5em;
		text-align: center;
		//cursor: pointer;
		position: relative;
		overflow: hidden;
		text-align: left;

		&:hover {
			transform: scale(1.01);
		}

		.shop-top-border {
			//content: "";
			position: absolute;
			width: 100%;
			height: 11px;
			//background: #009bcc;
			left: 0;
			top: 0;
			transition: background-color, filter linear 0.3s;
		}

		.shop-image {
			height: 90px;
			background: #c4c4c4;
			margin-top: 11px;
			overflow: hidden;
			position: relative;

			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			img {
				max-width: 100%;
			}

			.menu-link {
				position: absolute;
				right: 0;
				top: 13px;
				z-index: 10;
				background: #fff;
				border-top-left-radius: $button-border-radius;
				border-bottom-left-radius: $button-border-radius;
				padding: 8px 10px 8px 16px;

				a {
					font-size: 14px;
					font-weight: bold;
					color: #009bcc;
				}
			}
		}

		.shop-content {
			padding: 21px 28px 21px 28px;
			height: 100%;

			&.shop-content-image {
				height: calc(100% - 101px);
			}

			.shop-category-name {
				font-size: 12px;
				margin-bottom: 0.1rem;
			}
		}

		&.active {
			opacity: 1;
			transform: scale(1.03);
			.shop-top-border {
				//background: rgb(0, 125, 247);
				filter: grayscale(0%);
			}
		}

		.shop-name {
			font-family: Poppins;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 30px;
			position: relative;

			//padding: 21px 28px 12px 28px;
			margin: 0 0 12px 0;

			.bonus-value-badge {
				font-size: 0.6em;
				margin-top: 6px;
			}
		}

		.shop-description {
			font-family: Poppins;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 18px;

			//padding: 0 28px 0 28px;
			margin: 0;
		}

		.shop-select-button {
			margin: 15px 0 0 0;
			font-weight: bold;
			font-size: 15px;

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}

.empty-category {
	text-align: center;
}

#sidebar {
	margin: 3vw 0 15px 0;

	@include media-breakpoint-up(md) {
		margin-top: 0;
	}

	.recap-item {
		.recap-header {
			font-weight: bold;
			border-bottom: 1px solid #ddd;
			margin: 0 0 0.2em 0;
		}
		.recap-content {
			margin: 0 0 0.5em 0;
		}
	}
}

.discount-coupon {
	position: relative;

	.discount-coupon-header {
		border-bottom: 1px solid #bbb;

		&::after {
			font-family: "IcoFont";
			content: "\eab8";
			position: absolute;
			right: 0;
		}
	}
}

.purchase-data {
	background: #fcfcfc;
	box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	padding: 45px 39px 45px 39px;
	margin-bottom: 30px;

	@include media-breakpoint-up(md) {
		position: sticky;
		top: 0;
		max-height: 100vh;
		overflow-y: scroll;
	}

	h3 {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 1.8em;
		line-height: 55px;
		/* identical to box height */
		margin-bottom: 1em;
		text-align: center;
	}
}

.hidden {
	display: none;
}

.required-field {
	color: red;
}

.clusu-content {
	padding: 25px 0 50px 0;
}

#page-error {
	text-align: center;
	font-size: 22px;
	font-weight: bold;
	line-height: 35px;
	margin-top: 3em;
	color: #fff;

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background: #009bcc;
	}

	.text {
		margin-top: 2em;
	}
}

.content {
	padding-top: 3em;
}

.clusu-card {
	background: $card-bg;
	border-radius: $card-border-radius;
	box-shadow: $card-box-shadow;
}

.input-group.clusu-rounded {
	& > .form-control:not(:last-child) {
		border-top-left-radius: $button-border-radius;
		border-bottom-left-radius: $button-border-radius;
	}

	& > .input-group-append > .input-group-text:last-child {
		border-top-right-radius: $button-border-radius;
		border-bottom-right-radius: $button-border-radius;
	}
}

.embedded-video {
	overflow: hidden;
	margin: 30px auto 0 auto;
	position: relative;
	max-width: 600px;

	.video-container {
		padding-bottom: 56.25%;
		padding-top: 30px;
	}
}
.embedded-video iframe,
.embedded-video object,
.embedded-video embed {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.collapsable {
	height: 0;
	overflow: hidden;

	&.visible {
		height: auto;
	}
}

.light-blu-bg {
	background: #00b2ea !important;
}

.dark-blu-bg {
	background: #006f92 !important;
}

.shopping-vouchers-intro {
	.intro-title {
		margin-top: 18vh;
		@include media-breakpoint-up(md) {
			min-height: 100vh;

			margin-top: 0;
		}
	}
}
