@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

$border-color: rgb(192, 192, 192);

@include media-breakpoint-up(md) {
	.filters-collapse-button {
		display: none;
	}
}

.side-filters {
	display: none;
	font-size: 1rem;

	.filters-container {
		border-top: 1px solid $border-color;

		& > * {
			padding: 0.4rem 1rem;
		}

		h4 {
			margin: 0.3rem 0;
			font-weight: bold;
		}

		h6 {
			border-top: 1px solid $border-color;
			margin: 0;
			font-weight: bold;
			padding-top: 0.6rem;
		}

		ul {
			list-style: none;
			margin: 0;
		}
	}

	&.show {
		display: block;
	}

	@include media-breakpoint-up(md) {
		display: block;
	}
}

.shops-container {
	.shop {
		position: relative;
		overflow: hidden;
		border: none;
		margin-bottom: 1rem;
		color: #000;

		.side-color {
			position: absolute;
			height: 100%;
			width: 0.3rem;
			background: blue;
			top: 0;
			left: 0;
		}

		.media-container {
			background-color: rgb(233, 233, 233);
			width: 100%;
			height: 30vh;
			overflow: hidden;
			text-align: center;
			vertical-align: baseline;
			background-size: cover;
			background-position: center;

			&.no-image {
				background-size: initial;
				background-repeat: no-repeat;
			}

			img {
				width: 100%;
				max-height: 30vh;
			}

			@include media-breakpoint-up(md) {
				min-height: 0;
				width: 240px;
				height: 120px;
				margin: 0.6rem 0 0.6rem 0.8rem;

				img {
					max-width: 240px;
					max-height: 120px;
				}
			}
		}

		.card-body {
			padding: 0.8rem;

			.card-title {
				font-weight: bold;
			}

			p {
				margin: 0;
			}

			.extras-badge {
				top: -0.5rem;
				position: relative;
				margin-left: 0.5rem;
			}
		}
	}
}
