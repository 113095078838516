@import '~bootstrap/scss/bootstrap';

.page_header {
    text-align: center;
    margin: 1.5em 0 1em 0;

    font-weight: bold;

}
.comments{
    margin: 0.8em 0 2em 0;

    .comment{
        margin-bottom:1.5em;
    }
}

.sectionHeader{
    padding: 0 0 0.3em 0;
    border-bottom: 1px solid #eee;
}

.addIcon{
    transition: transform 0.3s linear;
    display: inline-block;

    &.open{
        transform: rotate(45deg);
    }
}

.commands{
    margin-bottom: 1em;
    align-items: flex-start;

    button{
        width: 100%;
        margin-bottom: 1em;
    }

    @include media-breakpoint-up(lg){
        display: flex;
        justify-content: space-between;

        button{
            width: auto;
            margin-bottom: 0;
        }
    }
}

.commentForm{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.visible{
        max-height: 100%;
    }
}