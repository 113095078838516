@import '../variables.scss';

#coupon-check{
    //margin-top: 25px;
    
    h5{
        text-align: center;
        margin: 20px 0 20px 0;
        padding-bottom:5px;
    }
}

.redeem-search-coupon{
    margin-bottom: 2em;
}